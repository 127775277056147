import { Branch, CountyModel } from '@dmv/common';
import { ContactPreferenceType } from './contact-information.model';
import { DocumentUpload } from './document-upload.type';
import { OwnerInformation } from './owner-information.model';
import { TransactionDocument } from './transaction-document.model';
import { AppointmentStatus, BaseTransactionStatus, UsedDocumentTypes } from './transaction.model';
import { VehicleType } from './vehicle-type.model';
import { VehicleUse } from './vehicle-use.model';

export enum OriginalRegistrationSectionId {
  MV_EIGHT_TWO = 'mv-eight-two',
  MV_EIGHT_TWO_B = 'mv-eight-two-b',
  MV_EIGHT_TWO_SN = 'mv-eight-two-sn',
  VEHICLE_TYPE = 'vehicle-type',
  PROOF_OF_ACQUISITION = 'proof-of-acquisition',
  PROOF_OF_OWNERSHIP = 'proof-of-ownership',
  PROOF_OF_OWNERSHIP_TRAILER = 'proof-of-ownership-trailer',
  PROOF_OF_INSURANCE = 'proof-of-insurance',
  DTF_802 = 'dtf-802',
  DTF_803 = 'dtf-803',
  CO_REGISTRANT_INFORMATION = 'coregistrant-information',
  REGISTRANT_INFORMATION = 'registrant-information',
  OWNER_PROOF_OF_BIRTH = 'owner-proof-of-birth',
  COREGISTRANT_PROOF_OF_BIRTH = 'coregistrant-proof-of-birth',
  COREGISTRANT_PROOF_OF_NAME = 'coregistrant-proof-of-name',
  REGISTRANT_PROOF_OF_BIRTH = 'registrant-proof-of-birth',
  REGISTRANT_PROOF_OF_NAME = 'registrant-proof-of-name',
  OWNER_PROOF_OF_NAME = 'owner-proof-of-name',
  SECOND_PARTY_AUTHORIZATION = 'second-party-authorization',
  VEHICLE_USE = 'vehicle-use',
  VEHICLE_USE_BOAT = 'vehicle-use-boat',
  VEHICLE_USE_TRAILER = 'vehicle-use-trailer',
  VEHICLE_INFORMATION = 'vehicle-information',
  VEHICLE_INFORMATION_BOAT = 'vehicle-information-boat',
  VEHICLE_INFORMATION_TRAILER = 'vehicle-information-trailer',
  TRANSACTION_INFORMATION = 'dtf-information',
  OWNER_INFORMATION = 'owner-information',
  ARRANGE_PICKUP = 'arrange-pickup',
}

/**
 * Interface for the 'OriginalRegistrationEntity' transaction
 * This is a placeholder that will be updated as we know
 * more about what this model will look like
 */
export interface OriginalRegistrationEntity extends OriginalRegistrationResponseDTO {
  id: number;
  submittedOn: string | null;
  updatedAt: string;
  // todo Move the Invoice type out of core so we can depend on it AND refactor the type
  invoices: Array<{
    amount: string;
    amountDetails: Array<{ amount: string; vehicleType: string }>;
    createdAt: string;
    id: number;
    initials: string;
    invoiceNumber: string;
    office: string;
    paymentType?: string;
    status: string;
    updatedAt: string;
  }>;
  isBusiness: boolean;
  isPayOnline?: boolean | null;
  isPersonalUse: boolean;
  isForHire?: boolean | null;
  firstName?: string;
  contactPreferenceType?: ContactPreferenceType;
  appointmentCounty?: string;
  appointmentLocationId?: string;
  appointmentReservationNumber?: string;
  appointmentStatus?: AppointmentStatus;
  appointmentTime?: string;
  barcodeUrl?: string;
  branchAddress?: string;
  branchAddress2?: string;
  branchCity?: string;
  branchName?: string;
  branchZip?: string;
  branchState?: string;
  documents?: TransactionDocument[];
  extractedVIN?: string;
  extractedVehicleYear?: number;
  hasInfoBeenRequested?: boolean;
  rejectionReason?: string;
  cancellationReason?: string;
  showSocialSecurityNotice?: boolean;
}

export interface OriginalRegistrationCreateDTO {
  proofOfOwnership: {
    damageExceeds75Percent: boolean | null;
    didSurrenderPlates?: boolean | null;
    providedDocuments: DocumentUpload[];
  };
  registrantInformation: RegistrantInformationSectionRequest;
  vehicleType: string;
  proofOfOwnershipTrailer?: {
    damageExceeds75Percent: boolean | null;
    didSurrenderPlates?: boolean | null;
    providedDocuments: DocumentUpload[];
  };
}

export interface OwnerInformationRequest extends OwnerInformation {
  providedDocuments: DocumentUpload[];
  transactionId?: string;
}

export interface VehicleUseRequest {
  providedDocuments: DocumentUpload[];
  transactionId?: string;
  vehicleUse: VehicleUse;
}

export interface OriginalRegistrationResponseDTO {
  transactionId: string;
  status: BaseTransactionStatus;
  vehicleType: VehicleType;
  vehicleYear?: number;
  vehicleMake?: string;
  snowmobileYear?: number;
  snowmobileMake?: string;
  boatYear?: number;
  boatMake?: string;
  trailerYear?: number;
  trailerMake?: string;
  vin?: string;
  createdAt: string;
  lastUpdated: string;
}

export interface CoRegistrantInformationSectionRequest {
  coregistrantFirstName: string;
  coregistrantCid?: string;
  coregistrantHasCurrentId: boolean;
  coregistrantDob: string;
  coregistrantEmail: string;
  coregistrantGender: string;
  coregistrantLastName: string;
  document: { originalStageIds: number[]; typeId: number };
  providedDocuments: Array<{ originalStageIds: number[]; typeId: number }>;
  transactionId: string;
}

export interface RegistrantInformationSectionRequest {
  address: string;
  addressChanged: boolean;
  apartment?: string;
  businessName?: string;
  businessRepTitle?: string;
  cid?: string;
  city: string;
  contactPreferenceType: ContactPreferenceType;
  county: string;
  countyId: number;
  document: { originalStageIds: number[]; typeId: number };
  dob?: string;
  email: string;
  firstName: string;
  formerName: string | null;
  gender?: string;
  hasCoregistrant?: boolean | null;
  hasCurrentId?: boolean;
  hasSecondParty: boolean | null;
  isBusiness: boolean;
  isOwner: boolean;
  isPersonalUse: boolean;
  lastName: string;
  mailingAddress: string;
  mailingAddressChanged: boolean;
  mailingApartment?: string;
  mailingCity: string;
  mailingCounty: string;
  mailingCountyId: number;
  mailingState: string;
  mailingZip: string;
  middleName?: string;
  nameChanged: boolean;
  phone: string;
  state: string;
  suffix?: string;
  transactionId?: string;
  zip: string;
}

export interface RegistrantInformation {
  address: string;
  addressChanged: boolean;
  apartment: string;
  businessIsOwner?: boolean | null;
  businessFirstName?: string | null;
  businessLastName?: string | null;
  businessName?: string | null;
  businessRepTitle?: string | null;
  cid?: string;
  city: string;
  confirmEmail: string;
  contactPreferenceType: ContactPreferenceType;
  hasCoregistrant?: boolean | null;
  hasSecondParty?: boolean | null;
  hiddenDateOfBirth: string;
  hiddenOutOfStateExpirationDate: string;
  county: string;
  countyId: number | null;
  dob?: string;
  email: string;
  firstName?: string;
  formerName: string;
  gender?: string;
  isOwner?: boolean | null;
  isSameAddress: boolean;
  lastName?: string;
  mailingAddress: string;
  mailingApartment: string;
  mailingCity: string;
  mailingCounty: string;
  mailingCountyId: number | null;
  mailingState: string;
  mailingZip: string;
  middleName: string;
  nameChanged: boolean;
  phone: string;
  preferContactViaSms: boolean;
  state: string;
  suffix: string;
  zip: string;
}

export interface SurveyFunctionContext {
  counties?: CountyModel[] | null;
  isBusiness?: boolean | null;
  offices?: Branch[] | null;
  usedDocumentTypes?: UsedDocumentTypes[];
  vehicleType?: string | null;
}
