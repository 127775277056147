import { DocumentType } from './document-type.enum';
import {
  DocAIClassificationData,
  DocAIExtractionData,
  DocAIQualityScore,
  DocAiResponse,
  FileUploadDriverLicense,
  FileUploadStaging,
} from './file-upload-staging';
import { AnswerMetadata, ReviewQuestion } from './survey-state.model';

export enum UploadStatus {
  BARCODED_INVALID = 'Error: Barcode Not Detected or Is Invalid. Please try again.',
  CORRUPTED_FILE = 'Corrupted File',
  FAILED = 'Upload Failed',
  FILELENGTH = 'Allows only 10 files',
  FILESIZE = 'Max file size is 15MB',
  MINFILESIZE = 'File is corrupt (0 bytes)',
  INVALID = 'Invalid Document Type',
  PAGE_COUNT_EXCEEDED = 'Page count exceeded',
  PENDING = 'Pending upload of other side',
  REQUIRED = 'Upload Required',
  SUCCESS = 'Success!',
  UPLOADING = 'Uploading',
  VALID = 'Valid',
  PASSWORDPROTECTED = 'File is password protected',
}

export enum DocumentSide {
  BACK = 'back',
  FRONT = 'front',
}

export interface Document {
  title: string;
  imgSrc: string[];
  listDisplay: boolean;
}

export class UploadDocumentDetail {
  public subTitle?: string;
  public description?: string;
  public instructions?: string;
  public documentType?: DocumentType;
  public placeHolderUrl?: string;
  public status?: UploadStatus;
  public files?: File[];
  public imageSrc?: string[];
  public required?: boolean;
  public id?: string;
  public documentTypeId?: number;
  public isSample?: boolean;
  public uploaded: boolean;
  public loading: boolean;
  public reUploadReq?: boolean;
  public qualityScoreDocAiResults?: DocAIQualityScore[];
  public classificationScoreDocAiResults?: DocAIClassificationData[];
  public extractionScoreDocAiResults?: DocAIExtractionData[];
  public docTypeChangeEligible?: boolean;
  public multiFileUpload?: boolean;
  public parentDocumentTypeId?: number | null;
  public sequentialUpload?: boolean;
  public side?: DocumentSide;

  constructor(uploadDocumentDetail: UploadDocumentDetail) {
    this.subTitle = uploadDocumentDetail.subTitle;
    this.description = uploadDocumentDetail.description;
    this.instructions = uploadDocumentDetail.instructions;
    this.documentType = uploadDocumentDetail.documentType;
    this.placeHolderUrl = uploadDocumentDetail.placeHolderUrl;
    this.status = uploadDocumentDetail.status;
    this.files = uploadDocumentDetail.files;
    this.imageSrc = uploadDocumentDetail.imageSrc;
    this.required = uploadDocumentDetail.required;
    this.id = uploadDocumentDetail.id;
    this.documentTypeId = uploadDocumentDetail.documentTypeId;
    this.isSample = uploadDocumentDetail.isSample;
    this.uploaded = uploadDocumentDetail.uploaded;
    this.reUploadReq = uploadDocumentDetail.reUploadReq || false;
    this.loading = uploadDocumentDetail.loading;
    this.qualityScoreDocAiResults = uploadDocumentDetail.qualityScoreDocAiResults ? [...uploadDocumentDetail.qualityScoreDocAiResults] : [];
    this.classificationScoreDocAiResults = uploadDocumentDetail.classificationScoreDocAiResults
      ? [...uploadDocumentDetail.classificationScoreDocAiResults]
      : [];
    this.extractionScoreDocAiResults = uploadDocumentDetail.extractionScoreDocAiResults
      ? [...uploadDocumentDetail.extractionScoreDocAiResults]
      : [];
    this.docTypeChangeEligible = uploadDocumentDetail.docTypeChangeEligible;
    this.multiFileUpload = uploadDocumentDetail.multiFileUpload;
    this.parentDocumentTypeId = uploadDocumentDetail.parentDocumentTypeId;
    this.sequentialUpload = uploadDocumentDetail.sequentialUpload;
    this.side = uploadDocumentDetail.side;
  }
}

export interface DocumentTypes {
  additionalInfo: string;
  birthProof?: boolean;
  businessProof?: boolean;
  childDocumentTypes: DocumentTypes[] | null;
  coRegistrant?: boolean;
  customImage: string;
  customPreview: boolean;
  foreign: boolean;
  hasBack: boolean;
  id: number;
  instructions: string;
  link: string;
  metadata: AnswerMetadata;
  name: string;
  nameProof?: boolean;
  popularity: number;
  residencyProof?: boolean;
  required: boolean;
  multiFileUpload?: boolean;
  sequentialUpload: boolean;
  shortName: string;
  verification: boolean;
}

export class UploadItem {
  public id?: string;
  public title?: string;
  public shortName?: string;
  public details?: UploadDocumentDetail[];
  public uploadedDocumentId?: number;
  public stagedDocumentId?: number;
  public originalStageIds?: number[];
  public uploaded?: boolean;
  public documentTypeId?: DocumentType; //todo free ourselves from this enum and make it a number
  public sequentialUpload?: boolean;
  public singleFileChoose?: boolean;
  public link?: string;
  public coRegistrant?: boolean;
  public uploadResponseData?: UploadResponse[];
  public multiFileUpload?: boolean;
  /**
   * If a document type has child document types, then this field should have a value
   */
  public parentDocumentTypeId?: number | null;

  constructor(uploadItem: UploadItem) {
    this.id = uploadItem.id;
    this.title = uploadItem.title;
    this.shortName = uploadItem.shortName;
    this.details = uploadItem.details.map(detail => new UploadDocumentDetail(detail));
    this.originalStageIds = uploadItem.originalStageIds ? [...uploadItem.originalStageIds] : [];
    this.uploadedDocumentId = uploadItem.uploadedDocumentId;
    this.stagedDocumentId = uploadItem.stagedDocumentId;
    this.uploaded = uploadItem.uploaded;
    this.documentTypeId = uploadItem.documentTypeId;
    this.singleFileChoose = uploadItem.singleFileChoose;
    this.link = uploadItem.link;
    this.coRegistrant = uploadItem.coRegistrant;
    this.uploadResponseData = uploadItem.uploadResponseData ? [...uploadItem.uploadResponseData] : [];
    this.parentDocumentTypeId = uploadItem.parentDocumentTypeId;
    this.sequentialUpload = uploadItem.sequentialUpload;
    this.multiFileUpload = uploadItem.multiFileUpload;
  }
}

export interface UploadResponse {
  docAiProcessors?: string[];
  docAiResultsId?: string;
  stagedId: number;
  side?: string;
  identificationCard?: FileUploadDriverLicense;
}

export interface UploadItemResult {
  docAiResults?: DocAiResponse[] | undefined;
  stagingResponse: FileUploadStaging;
}

export interface DocumentsReview {
  questions: ReviewQuestion[];
  uploads?: {
    title: string;
    documents: Document[];
  };
  answer?: string;
}

export class MultiUploadPanelsEvent {
  constructor(public action: MultiUploadPanelsAction, public data: UploadItem) {}
}

export enum MultiUploadPanelsAction {
  COMPLETED = 'completed',
  FILE_UPLOADED = 'fileUploaded',
}
