import { ContactPreferenceType } from './contact-information.model';
import { IDType } from './id-type.enum';
import { OriginalPermitDTO } from './original-permit.model';
import { TransactionDocument } from './transaction-document.model';
import { AppointmentStatus, BaseTransactionStatus } from './transaction.model';

export class OriginalPermitGetResponse implements OriginalPermitDTO {
  public id: number;
  public transactionId: string;
  public status: BaseTransactionStatus;
  public testStatus?: string;
  public firstName?: string;
  public permitType?: string;
  public county?: string;
  public testLink?: string;
  public barcodeUrl?: string;
  public testLinkExpiresAt?: string;
  public testCanBeRetakenAt?: string;
  public appointmentCounty?: string;
  public appointmentTime?: string;
  public appointmentReferenceNumber?: string;
  /**
   * Used to generate QR codes; this is different from the {@link appointmentReferenceNumber}
   */
  public appointmentReservationNumber?: string;
  public appointmentStatus?: AppointmentStatus;
  public branchName?: string;
  public branchAddress?: string;
  public branchAddress2?: string;
  public branchCity?: string;
  public branchState?: string;
  public branchZip?: string;
  public submittedOn?: string;
  public updatedAt: string;
  public createdAt: string;
  public minorAttestationRequired?: boolean;
  public hasInfoBeenRequested?: boolean;
  public idType?: IDType;
  public idRequested?: string;
  public documents?: TransactionDocument[] = [];
  public latestMessageContent?: string;
  public contactPreferenceType?: ContactPreferenceType;
  public showSocialSecurityNotice?: boolean;
}
